import { PositionWizard } from '@/modules/position/components/PositionWizard';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { DocumentTitle } from '@/shared/components/DocumentTitle.tsx';
import { FullscreenLoadingSuspenseBoundary } from '@/shared/components/FullscreenLoadingSuspenseBoundary.tsx';
import { BeforeUnloadContextProvider } from '@/shared/context/BeforeUnloadContext.tsx';

export function PositionPage() {
    const { positionId } = useParams<{ positionId: string }>();
    const { t } = useTranslation();

    return (
        <>
            <DocumentTitle title={t('positions.create-position')} />
            <FullscreenLoadingSuspenseBoundary>
                {positionId !== undefined ? (
                    <BeforeUnloadContextProvider defaultEnabled>
                        <PositionWizard positionId={positionId} />
                    </BeforeUnloadContextProvider>
                ) : null}
            </FullscreenLoadingSuspenseBoundary>
        </>
    );
}
