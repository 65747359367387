import { useZodForm } from '@/shared/hooks/useZodForm';
import { useCallback, useEffect } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { IndividualLineItemForm } from './IndividualLineItemForm';
import { IndividualLineItemSchema, PartialIndividualLineItemSchema } from '../shared/IndividualLineItemSchema';
import { useFulfillmentContext } from '../context/FulfillmentContext';
import { LineItemListingSchema } from '@schuettflix/interfaces';
import { ProductFallbackDataMap } from '../types/ProductFallbackDataMap';

type LineItemListing = z.infer<typeof LineItemListingSchema>;

const FormSchema = z.object({
    items: z.array(PartialIndividualLineItemSchema).min(1),
});

type ProductSelectionIndividualLineItemListingProps = {
    onIsDirtyChange: (isDirty: boolean) => void;
    productFallbackDataMap: ProductFallbackDataMap;
};

export const ProductSelectionIndividualLineItemListing = ({
    onIsDirtyChange: setIsDirty,
    productFallbackDataMap,
}: ProductSelectionIndividualLineItemListingProps) => {
    const { t } = useTranslation();

    const { control, register, formState } = useZodForm({
        schema: FormSchema,
        defaultValues: {
            items: [{ referenceDocument: '', licensePlate: '', serviceDate: '' }],
        },
        mode: 'onChange',
    });

    const { fields, append, remove, insert } = useFieldArray({
        control,
        name: 'items',
    });

    const values = useWatch({ control });

    useEffect(() => {
        // for some reason formState.isDirty doesn't work as expected, so we have to calculate it manually
        const isDirty = JSON.stringify(values) !== JSON.stringify(formState.defaultValues);
        setIsDirty(isDirty);
    }, [values, formState, setIsDirty]);

    // set the values (which represents individual line items) to the FulfillmentContext
    const { setLineItemListing, productGroup, products } = useFulfillmentContext();
    /**
     * Gets the according transport product as a lineItem from a given material productId in franco and merchant cases.
     * If we are in a standard case or if the given productId is not a material, returns undefined.
     *
     * @returns A lineItem of the related transport product if applicable.
     */
    const getRelatedTransportLineItem = useCallback(
        <TLineItem extends { productId: string }>(lineItem: TLineItem): TLineItem | undefined => {
            if (productGroup.type === 'STANDARD') return;

            const product = products.find(p => p.id === lineItem.productId);
            if (product?.category !== 'MATERIAL') return;

            const transportProduct = products.find(p => p.category === 'TRANSPORT');
            if (!transportProduct) throw new Error('Expected to find a transport product for FRANCO/MERCHANT');
            return { ...lineItem, productId: transportProduct.id };
        },
        [productGroup.type, products]
    );
    useEffect(() => {
        const validItemsWithoutSortIndex = (values.items || []).flatMap((item, index) => {
            const { success, data } = IndividualLineItemSchema.safeParse(item);
            if (!success) return [];

            const groupKey = index;
            const withGroupKey = <TLineItem extends object>(lineItem: TLineItem) => ({ ...lineItem, groupKey });
            const transportLineItem = getRelatedTransportLineItem(data);
            return (
                transportLineItem ? [withGroupKey(data), withGroupKey(transportLineItem)] : [withGroupKey(data)]
            ) satisfies Omit<LineItemListing, 'sortIndex'>[];
        });
        const validItems = validItemsWithoutSortIndex.map((item, index) =>
            LineItemListingSchema.parse({ ...item, sortIndex: index })
        );
        setLineItemListing(validItems);
        return () => {
            setLineItemListing(null);
        };
    }, [setLineItemListing, values, getRelatedTransportLineItem]);

    // add a new empty LineItemForm whenever the former last form was filled with some data and autoselect the next field
    useEffect(() => {
        if (values.items?.slice(-1)[0].productId) {
            append(
                {},
                {
                    focusName: `items.${values.items.length - 1}.amount`,
                }
            );
        }
    }, [values, append]);

    return (
        <div className="mt-8 rounded bg-white p-4">
            <p className="font-copy-sm-strong uppercase">
                {t('sections.productGroupSelection.individualLineItemListing.sectionTitle')}
            </p>
            <form className="mt-4">
                <div className="flex flex-col gap-4">
                    {fields.map((field, index) => (
                        <IndividualLineItemForm
                            key={field.id}
                            control={control}
                            register={register}
                            index={index}
                            disableActions={index === fields.length - 1}
                            productFallbackDataMap={productFallbackDataMap}
                            /*
                                Forces validation when the form is the only item in the list,
                                ensuring errors are shown even if empty.
                            */
                            forceValidation={fields.length === 1}
                            onRemove={() => remove(index)}
                            onCopy={value => {
                                insert(index + 1, value, {
                                    shouldFocus: false,
                                });
                            }}
                        />
                    ))}
                </div>
            </form>
        </div>
    );
};
