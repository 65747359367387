import { Outlet, useParams } from 'react-router';
import { MarketContextProvider } from '@/shared/context/MarketContext.tsx';
import { useOrder } from '@/clients/order/useOrder.ts';

type OrderRouteParams = { orderId: string };

function InnerOrderRoute({ orderId }: OrderRouteParams) {
    const { data } = useOrder(orderId);

    if (!data) {
        return;
    }

    return (
        <MarketContextProvider defaultMarketCode={data.marketCode}>
            <Outlet />
        </MarketContextProvider>
    );
}

export function RouteWithOrderId() {
    const { orderId } = useParams<OrderRouteParams>();
    if (!orderId) {
        return;
    }
    return <InnerOrderRoute orderId={orderId} />;
}
