import * as Sentry from '@sentry/react';
import { InternalAxiosRequestConfig } from 'axios';

export const sentryInterceptor = (request: InternalAxiosRequestConfig<unknown>) => {
    const activeSpan = Sentry.getActiveSpan();
    const rootSpan = activeSpan ? Sentry.getRootSpan(activeSpan) : undefined;

    // Create `sentry-trace` header
    const sentryTraceHeader = rootSpan ? Sentry.spanToTraceHeader(rootSpan) : undefined;

    // Create `baggage` header
    const sentryBaggageHeader = rootSpan ? Sentry.spanToBaggageHeader(rootSpan) : undefined;
    request.headers['sentry-trace'] = sentryTraceHeader;
    request.headers['baggage'] = sentryBaggageHeader;

    return request;
};
