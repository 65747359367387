import { TagSection } from '@/shared/components/TagSection.tsx';
import { getTagType } from '@/shared/utils/getTagType.ts';
import { NestedOrderProductResponse, PRODUCT_CATEGORY_LABEL, PRODUCT_GROUP_TYPE } from '@schuettflix/interfaces';
import { useTranslation } from 'react-i18next';
import { useFormatters } from '@/shared/hooks/useFormatters/useFormatters.ts';
import { useBalanceSheetCalculations } from '@/shared/components/ProductGroupHeader/hooks/useBalanceSheetCalculations.ts';
import { useFrancoIndicator } from '../hooks/useFrancoIndicator.ts';
import { Price } from '@schuettflix/conversion';
import { useFeatureFlag } from '@/tools/featureFlags/useFeatureFlag.ts';

// A product with a weak quote is a real product, but its quote (form values like prices, ...)
// are potentially not fully filled out. Therefore the quote is not submitted to the backend and values like creation date or id are missing.
// We need the weak quote to calculate the balance sheet, while the user is filling the form and not all values are yet filled. For the not filled
// values we calculate with default fallback values (see getProductsWithPurifiedQuotes() beneath).
type ProductWithWeakQuote = Pick<NestedOrderProductResponse, 'templateId' | 'category'> & {
    id?: string;
    quote: Partial<NestedOrderProductResponse['quote']>;
};
export type ProductWithPurifiedWeakQuote = Omit<ProductWithWeakQuote, 'quote'> & {
    quote: NonNullable<
        ProductWithWeakQuote['quote'] & {
            salesPrice: number;
            purchasePrice: number;
            amount: number;
            unit: string;
            templateId?: string;
        }
    >;
};

export type ProductGroupBalanceProps = {
    products: ProductWithWeakQuote[];
    productGroupType: PRODUCT_GROUP_TYPE;
    productGroupTemplateId: string;
};

// Here we purify the weak quotes of the products, which means that we set all not filled values to a default fallback value.
function generateProductsWithPurifiedWeakQuotes(products: ProductWithWeakQuote[]): ProductWithPurifiedWeakQuote[] {
    return products.map(product => ({
        ...product,
        quote: {
            salesPrice: product.quote?.salesPrice
                ? new Price(product.quote.salesPrice, product.quote.currencyCode ?? 'EUR').toNumber()
                : 0,
            purchasePrice: product.quote?.purchasePrice
                ? new Price(product.quote.purchasePrice, product.quote.currencyCode ?? 'EUR').toNumber()
                : 0,
            amount: product.quote?.amount ? product.quote?.amount : 0,
            unit: product.quote?.unit ?? 'TON',
            templateId: product.templateId ?? undefined,
        },
    }));
}

export function ProductGroupBalance({ products, productGroupType, productGroupTemplateId }: ProductGroupBalanceProps) {
    const { t } = useTranslation();
    const { formatNumberWithCurrency, formatPercentage } = useFormatters();
    const productsWithPurifiedWeakQuotes = generateProductsWithPurifiedWeakQuotes(products);
    const { customerPrice, materialPrice, servicePrice, transportPrice, takeRate, materialTransportPrice } =
        useBalanceSheetCalculations(productsWithPurifiedWeakQuotes);
    const francoIndicator = useFrancoIndicator(
        productsWithPurifiedWeakQuotes,
        productGroupType,
        productGroupTemplateId
    );
    const isSplittedMerchant =
        useFeatureFlag<boolean>('generic-order-templates-new-merchant-split', false) && productGroupType === 'MERCHANT';

    return (
        <div className="flex justify-between gap-6">
            {francoIndicator && (
                <TagSection
                    title={t('product.balanceSheet.francoIndicator')}
                    description={francoIndicator?.amountLabel}
                    label={francoIndicator?.pricePerUnitLabel}
                />
            )}
            <div className="ml-auto flex flex-wrap justify-end gap-6 divide-x">
                <TagSection
                    title={t('product.balanceSheet.client')}
                    label={formatNumberWithCurrency(customerPrice < 0 ? -1 * customerPrice : customerPrice)}
                    type={getTagType(customerPrice)}
                />

                {materialPrice !== null && (
                    <TagSection
                        className="pl-6"
                        title={
                            isSplittedMerchant
                                ? t(PRODUCT_CATEGORY_LABEL['MATERIAL_TRANSPORT'])
                                : t(PRODUCT_CATEGORY_LABEL['MATERIAL'])
                        }
                        label={formatNumberWithCurrency(materialPrice < 0 ? -1 * materialPrice : materialPrice)}
                        type={getTagType(materialPrice, true)}
                    />
                )}

                {transportPrice !== null && !isSplittedMerchant && (
                    <TagSection
                        className="pl-6"
                        title={t(PRODUCT_CATEGORY_LABEL['TRANSPORT'])}
                        label={formatNumberWithCurrency(transportPrice < 0 ? -1 * transportPrice : transportPrice)}
                        type={getTagType(transportPrice, true)}
                    />
                )}

                {servicePrice !== null && (
                    <TagSection
                        className="pl-6"
                        title={t(PRODUCT_CATEGORY_LABEL['MATERIAL_SERVICE'])}
                        label={formatNumberWithCurrency(servicePrice < 0 ? -1 * servicePrice : servicePrice)}
                        type={getTagType(servicePrice, true)}
                    />
                )}

                {materialTransportPrice !== null && (
                    <TagSection
                        className="pl-6"
                        title={t(PRODUCT_CATEGORY_LABEL['MATERIAL_TRANSPORT'])}
                        label={formatNumberWithCurrency(
                            materialTransportPrice < 0 ? -1 * materialTransportPrice : materialTransportPrice
                        )}
                        type={getTagType(materialTransportPrice, true)}
                    />
                )}

                <TagSection
                    className="pl-6"
                    title={t('product.balanceSheet.takeRate')}
                    label={takeRate !== null ? formatPercentage(takeRate) : undefined}
                    description={takeRate === null ? '---' : undefined}
                    type={getTagType(takeRate ?? 0)}
                />
            </div>
        </div>
    );
}
