import { z } from 'zod';
import { ORDER_ORGANIZATION_ROLE_SCHEMA } from '../finance-settings/role';
import { TransactionSchema } from '../order-summary/transactions';
import { TransactionalTotalsSchema } from '../order-summary/totals';
import { PaymentSettingsWithTransactionalRoleResponseSchema } from '../finance-settings/payment-settings';
import { PRODUCT_GROUP_TYPE_SCHEMA } from '../product-group/product-group-type.ts';

export const GetPositionOverviewUrlSchema = z.object({
    positionId: z.string().uuid(),
});

export const PositionOverviewFinanceSettingSchema = PaymentSettingsWithTransactionalRoleResponseSchema.omit({
    paymentTermId: true,
}).merge(
    z.strictObject({
        paymentTerm: z
            .strictObject({
                id: z.number(),
                localizedDescription: z.string(),
            })
            .nullable(),
    })
);

export const GetPositionOverviewResponseSchema = z.strictObject({
    organizations: z.array(
        z.strictObject({
            orgId: z.number(),
            functionalRoles: z.array(ORDER_ORGANIZATION_ROLE_SCHEMA),
            financeSettings: z.array(PositionOverviewFinanceSettingSchema),
            totals: z.array(TransactionalTotalsSchema),
            transactions: z.array(
                TransactionSchema.extend({
                    unitTaxClass: z.object({ name: z.string(), rate: z.number() }).optional(),
                    groupType: PRODUCT_GROUP_TYPE_SCHEMA,
                })
            ),
        })
    ),
});

export type GetPositionOverviewResponse = z.infer<typeof GetPositionOverviewResponseSchema>;
