import React from 'react';
import { usePosition } from '@/clients/position/usePosition.ts';
import { usePlatformInfo } from '@/clients/platformInfo/usePlatformInfo.ts';
import { PageHeader } from '@/shared/components/PageHeader.tsx';
import { useTranslation } from 'react-i18next';
import { SectionSummary } from '@/shared/components/SectionSummary.tsx';
import { ClientInformationSummaryContent } from '@/shared/components/ClientInformationSummaryContent.tsx';
import { PositionSettings } from '@/modules/position/components/PositionSettings/PositionSettings.tsx';
import { PositionSummary } from '@/modules/position/components/PositionSummary/PositionSummary.tsx';
import { PositionSectionWrapper } from './PositionSectionWrapper.tsx';
import { PositionProductSelection } from './PositionProductSelection.tsx';
import { useEditPositionState } from '@/modules/position/hooks/useEditPositionState.ts';
import {
    POSITION_SETTINGS_SECTION_NUMBER,
    POSITION_SUMMARY_SECTION_NUMBER,
    PRODUCT_SELECTION_SECTION_NUMBER,
    useCurrentSection,
} from '@/modules/position/hooks/useCurrentSection.ts';
import { SectionPreview } from '@/shared/components/SectionPreview.tsx';
import { PositionSettingsPreview } from '@/modules/position/components/PositionSettings/PositionSettingsPreview.tsx';
import { PositionProductSelectionPreview } from '@/modules/position/components/PositionProductSelectionPreview.tsx';

export interface PositionSection {
    isSectionOpen: boolean;
    onEditCurrentSection: () => void;
    onMoveToNextSection?: () => void;
}

export interface PositionWizardProps {
    positionId: string;
}

export const PositionWizard: React.FC<PositionWizardProps> = ({ positionId }) => {
    const { t } = useTranslation();
    const { isEditing } = useEditPositionState();
    const { data: position } = usePosition(positionId);
    const { data: platformInfo } = usePlatformInfo();
    const platformOrganizationId = platformInfo?.platform.market.platformOrganizationId;
    const [currentSectionNumber, setCurrentSectionNumber] = useCurrentSection(PRODUCT_SELECTION_SECTION_NUMBER);

    const isProductSelectionSectionActive = currentSectionNumber === PRODUCT_SELECTION_SECTION_NUMBER;
    const isSettingsSectionActive = currentSectionNumber === POSITION_SETTINGS_SECTION_NUMBER;
    const isSummarySectionActive = currentSectionNumber === POSITION_SUMMARY_SECTION_NUMBER;

    if (!position || !platformOrganizationId) {
        return null;
    }

    return (
        <div className="bg-light-gray-200 min-h-screen">
            <PageHeader title={isEditing ? t('positions.edit-position') : t('positions.create-position')} />
            <main className="py-18 flex flex-col items-center gap-4 px-6">
                <PositionSectionWrapper>
                    <SectionSummary title={`1. ${t('sections.clientInformation.title')}`}>
                        <ClientInformationSummaryContent
                            organizationId={position.customerInfo.organizationId}
                            constructionProjectId={position.customerInfo.constructionProjectId}
                            manualCostCenter={position.customerInfo.manualCostCenter}
                        />
                    </SectionSummary>
                </PositionSectionWrapper>

                <PositionSectionWrapper className="sm:max-w-[1280px]" showDividers={isProductSelectionSectionActive}>
                    {isProductSelectionSectionActive ? (
                        <PositionProductSelection
                            positionId={positionId}
                            onMoveToNextSection={() => setCurrentSectionNumber(POSITION_SETTINGS_SECTION_NUMBER)}
                        />
                    ) : (
                        <PositionProductSelectionPreview
                            positionId={positionId}
                            onEditCurrentSection={() => setCurrentSectionNumber(PRODUCT_SELECTION_SECTION_NUMBER)}
                        />
                    )}
                </PositionSectionWrapper>

                <PositionSectionWrapper showDividers={isSettingsSectionActive}>
                    {isSettingsSectionActive ? (
                        <PositionSettings
                            positionId={positionId}
                            onMoveToNextSection={() => setCurrentSectionNumber(POSITION_SUMMARY_SECTION_NUMBER)}
                        />
                    ) : (
                        <PositionSettingsPreview
                            positionId={positionId}
                            onEditCurrentSection={() => setCurrentSectionNumber(POSITION_SETTINGS_SECTION_NUMBER)}
                        />
                    )}
                </PositionSectionWrapper>

                <PositionSectionWrapper showTopDivider={isSummarySectionActive}>
                    {isSummarySectionActive ? (
                        <PositionSummary positionId={positionId} />
                    ) : (
                        <SectionPreview
                            description={t('position.sections.summary.description')}
                            headline={`4. ${t('position.sections.summary.title')}`}
                        />
                    )}
                </PositionSectionWrapper>
            </main>
        </div>
    );
};
