import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { SUPPORTED_UNITS_SCHEMA, CURRENCY_SCHEMA } from '@schuettflix/interfaces';
import { isValid as isValidDate, parseISO } from 'date-fns';

export function useCustomRequestProductSchema() {
    const { t } = useTranslation();

    return z.object({
        amount: z
            .number({ required_error: t('product.addPosition.errorMessage.pleaseEnterValue') })
            .min(0)
            .positive(),
        purchasePrice: z.string({ required_error: t('product.addPosition.errorMessage.pleaseEnterValue') }).min(1),
        salesPrice: z.string({ required_error: t('product.addPosition.errorMessage.pleaseEnterValue') }).min(1),
        unit: SUPPORTED_UNITS_SCHEMA.refine(unit => unit, t('product.addPosition.errorMessage.pleaseEnterValue')),
        partnerOrganizationId: z
            .number({ required_error: t('product.addPosition.errorMessage.pleaseEnterValue') })
            .int()
            .positive(),
        serviceDate: z.string().refine(date => isValidDate(parseISO(date)), {
            message: t('product.addPosition.errorMessage.pleaseEnterValue'),
        }),
        purchaseTaxClassId: z.string({ message: t('product.addPosition.errorMessage.pleaseEnterValue') }),
        salesTaxClassId: z.string({ message: t('product.addPosition.errorMessage.pleaseEnterValue') }),
        productName: z.string({ required_error: t('product.addPosition.errorMessage.pleaseEnterValue') }).min(1),
        currencyCode: CURRENCY_SCHEMA,
    });
}

export type CustomRequestProductSchema = z.infer<ReturnType<typeof useCustomRequestProductSchema>>;

type RequiredProperties<T> = {
    [K in keyof T]: T[K] | undefined;
};

export const customRequestProductSchemaDefaults: RequiredProperties<CustomRequestProductSchema> = {
    amount: undefined,
    unit: 'TON',
    partnerOrganizationId: undefined,
    purchasePrice: undefined,
    purchaseTaxClassId: 'S-DE',
    salesPrice: undefined,
    salesTaxClassId: 'S-DE',
    serviceDate: undefined,
    productName: undefined,
    currencyCode: 'EUR',
};
