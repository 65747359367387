import { LDProvider } from 'launchdarkly-react-client-sdk';
import { getUserFromToken } from '@/tools/featureFlags/utils/getUserFromToken.ts';
import { authTokenFromLocalStorage, isAuthenticated } from '@/shared/utils/localStorage.ts';
import { ReactNode, useEffect, useState } from 'react';

export const FeatureFlagProvider = ({ children }: { children: ReactNode }) => {
    const user = isAuthenticated() ? getUserFromToken(authTokenFromLocalStorage()) : undefined;
    const [context, setContext] = useState({
        kind: 'user',
        key: user?.email ?? 'anonymous',
        name: user?.name,
        email: user?.email,
    });

    useEffect(() => {
        setContext({
            kind: 'user',
            key: user?.email ?? 'anonymous',
            name: user?.name,
            email: user?.email,
        });
    }, [user?.email, user?.name]);

    return (
        <LDProvider
            reactOptions={{ useCamelCaseFlagKeys: false }}
            clientSideID={import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID}
            deferInitialization={true}
            context={context}
        >
            {children}
        </LDProvider>
    );
};
