import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropzone, File, FileUploadOptions } from '@schuettflix/react-components';
import { useFulfillmentContext } from '@/modules/fulfillment/context/FulfillmentContext.tsx';
import { useSectionsValidity } from '@/modules/fulfillment/hooks/useSectionsValidity.ts';
import { useFulfillmentSectionsErrors } from '../hooks/useFulfillmentSectionsErrors.ts';
import { cn } from '@/shared/utils/cn.ts';
import {
    ALLOWED_MIME_TYPE,
    MAX_FILE_SIZE_IN_BYTES,
    UploadFile,
    useFulfillmentUploadContext,
} from '../context/FulfillmentUploadContext.tsx';

interface DocumentUploadSectionProps {
    disabled?: boolean;
}

export const DocumentUploadSection: React.FC<DocumentUploadSectionProps> = ({ disabled }) => {
    const { t } = useTranslation();
    const { documents, errors, isSubmitted, lineItemListing } = useFulfillmentContext();

    const { uploads, addUploads, removeUpload } = useFulfillmentUploadContext();

    const hasFileUploadErrors = uploads.some(file => file.status === 'failed');

    useFulfillmentSectionsErrors([
        ...(documents.length === 0 && !hasFileUploadErrors
            ? [
                  {
                      field: 'documentUrls',
                      message: t('fulfillment.error.noDocuments'),
                      scrollToElement: () => {
                          document.getElementById('documentUploadSection')?.scrollIntoView({ behavior: 'smooth' });
                      },
                  },
              ]
            : []),
        ...(hasFileUploadErrors
            ? [
                  {
                      field: 'documentUrls',
                      message: t('fulfillment.error.invalidDocuments'),
                      scrollToElement: () => {
                          document.getElementById('documentUploadSection')?.scrollIntoView({ behavior: 'smooth' });
                      },
                  },
              ]
            : []),
    ]);

    useSectionsValidity('document-upload', !hasFileUploadErrors && documents.length > 0);

    const hasUploadErrors = () => errors.some(error => error.field === 'documentUrls');

    const toUploadOptions = (file: UploadFile): FileUploadOptions | undefined =>
        file.status === 'uploading'
            ? {
                  progress: file.progress,
                  error: file.error,
                  onCancel: () => {
                      file.abortController?.abort();
                      file.status = 'failed';
                  },
                  onRetry: () => {},
              }
            : file.status === 'failed'
              ? {
                    progress: 100,
                    error: file.error,
                    onCancel: () => {
                        removeUpload(file);
                    },
                    onRetry: () => {},
                }
              : undefined;

    const isDocumentUrlAssigned = (url: string) =>
        lineItemListing?.flatMap(({ documentUrls }) => documentUrls ?? []).includes(url);

    const notAssignedUploads = uploads.filter(upload => !isDocumentUrlAssigned(upload.remotePath));
    const assignedUploads = uploads.filter(upload => isDocumentUrlAssigned(upload.remotePath));

    return (
        <div id="documentUploadSection">
            <Dropzone
                accept={ALLOWED_MIME_TYPE}
                data-test="document-upload"
                onChange={addUploads}
                translations={{
                    label: t('fulfillment.documentUpload.label'),
                    info: t('fulfillment.documentUpload.info'),
                    drop: t('fulfillment.documentUpload.drop'),
                    errors: {
                        maxOneFile: '', // not happening if multiple is set
                        invalidFileSize: () => t('fulfillment.documentUpload.errors.fileSize', { size: 20 }).toString(),
                        invalidFileType: () => t('fulfillment.documentUpload.errors.fileType'),
                    },
                }}
                maxFileSize={MAX_FILE_SIZE_IN_BYTES}
                multiple
                className={cn('mb-4', { 'border-critical border': hasUploadErrors() && isSubmitted })}
                disabled={disabled}
            />
            <div className="flex flex-col gap-4">
                {isSubmitted &&
                    errors
                        .filter(error => error.field === 'documentUrls')
                        .map((error, index) => (
                            <p className="font-copy-sm text-critical" key={index}>
                                {error.message}
                            </p>
                        ))}
                {notAssignedUploads.map((file, index) => (
                    <File
                        file={file.file}
                        key={index}
                        upload={toUploadOptions(file)}
                        onDelete={() => removeUpload(file)}
                    />
                ))}
                {assignedUploads.length > 0 && (
                    <h3 className="font-copy-md-strong -mb-2">{t('fulfillment.documentUpload.assignedUploads')}</h3>
                )}
                {assignedUploads.map((file, index) => (
                    <File
                        file={file.file}
                        key={index}
                        upload={toUploadOptions(file)}
                        onDelete={() => removeUpload(file)}
                    />
                ))}
            </div>
        </div>
    );
};
