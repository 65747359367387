import { FieldValues, useFormContext, useWatch } from 'react-hook-form';

/**
 * Custom hook to get and watch form values using react-hook-form.
 *
 * This hook combines the functionality of `useWatch` and `getValues` from react-hook-form.
 * It subscribes to form value updates and always merges with the latest form values.
 *
 * It is using this approach to ensure that the form values are always up-to-date.
 * @see https://react-hook-form.com/docs/usewatch
 *
 * @template TFieldValues - The type of the form values that we expect to be in the FormContext.
 * @returns An object containing the current form values.
 */
export const useFormValues = <TFieldValues extends FieldValues>() => {
    const { getValues } = useFormContext<TFieldValues>();

    return {
        ...useWatch<TFieldValues>(), // subscribe to form value update
        ...getValues(), // always merge with latest form values
    };
};
