import { DeepPartialSkipArrayKey, FieldValues } from 'react-hook-form';
import { useFormValues } from './useFormValues';
import { useDeepCompareEffect } from 'react-use';
import { merge } from 'lodash-es';

/**
 * Custom hook that triggers a callback function when form values are updated.
 *
 * @template TFieldValues - The type of the form values we expect to be in the FormContext.
 * @param onFormValueUpdate - Callback function to be called with updated form values.
 */
export const useOnFormValueUpdate = <TFieldValues extends FieldValues>(
    onFormValueUpdate: (formValues: DeepPartialSkipArrayKey<TFieldValues> & TFieldValues) => void
) => {
    const formValues = useFormValues<TFieldValues>();

    useDeepCompareEffect(() => {
        onFormValueUpdate(formValues);
    }, [formValues]);

    /**
     * Function to manually trigger the form value update callback with optional new field values.
     *
     * @param fieldValues - Optional new field values to merge with the current form values.
     */
    const triggerUpdate = (fieldValues?: DeepPartialSkipArrayKey<TFieldValues>) => {
        onFormValueUpdate(merge({}, formValues, fieldValues));
    };

    return {
        triggerUpdate,
    };
};
