import { isKeyOf } from '@/shared/utils/isKeyOf';
import { SafeParseReturnType, z, ZodIssue, ZodIssueCode, ZodSchema } from 'zod';

type AnyKey = keyof any;

type PartialRecordWithDefault<K extends AnyKey, T> = Partial<Record<K | 'DEFAULT', T>> | T;

type ErrorTranslationMap<TSchema extends ZodSchema> = PartialRecordWithDefault<
    keyof z.infer<TSchema>,
    PartialRecordWithDefault<ZodIssueCode, string>
>;

/**
 * Get flattened and translated Zod error messages.
 *
 * You can provide a map for translating error messages. The map can be structured in different ways (sorted by priority):
 * - field name -> error code -> message
 * - field name -> DEFAULT -> message
 * - field name -> message
 * - error code -> message
 * - DEFAULT -> error code -> message
 * - DEFAULT -> DEFAULT -> message
 * - DEFAULT -> message
 * - message
 *
 * @returns Flattened and translated error messages or null if validation is successful.
 */
export const getFlattenedAndTranslatedZodErrorMessages = <TSchema extends ZodSchema>({
    schema,
    input,
    errorTranslationMap: errorTranslationMapWithDefaults,
    shouldUseIssueCodeAsFallback,
}: {
    /**
     * The Zod schema to validate against.
     */
    schema: TSchema;
    /**
     * The input data to validate.
     */
    input: Partial<z.input<NoInfer<TSchema>>>;
    /**
     * A map for translating error messages.
     */
    errorTranslationMap: ErrorTranslationMap<TSchema>;
    /**
     * Whether to use the issue code as a fallback message. Default is false, which means that the issue message is used as fallback.
     */
    shouldUseIssueCodeAsFallback: boolean;
}) => {
    const result = schema.safeParse(input) as SafeParseReturnType<z.input<TSchema>, z.output<TSchema>>;

    if (result.success) {
        return null;
    }
    return result.error.flatten(
        flattenedErrorMapper<typeof schema>(errorTranslationMapWithDefaults, { shouldUseIssueCodeAsFallback })
    );
};

/**
 * Flattened error mapper for Zod issues.
 *
 * @param errorTranslationMapWithDefaults A map for translating error messages.
 * @param options Options for error mapping.
 * @returns A function that maps Zod issues to error messages.
 */
const flattenedErrorMapper = <TSchema extends ZodSchema>(
    errorTranslationMapWithDefaults: ErrorTranslationMap<TSchema>,
    options?: { shouldUseIssueCodeAsFallback?: boolean }
): ((issue: ZodIssue) => string) => {
    return issue => {
        const fieldName = issue.path[0];
        const errorCode = issue.code;

        // message
        if (typeof errorTranslationMapWithDefaults === 'string') return errorTranslationMapWithDefaults;

        const getByErrorCode = (mapOrString: PartialRecordWithDefault<typeof errorCode, string>) => {
            if (typeof mapOrString === 'string') return mapOrString;
            return mapOrString[errorCode] ?? mapOrString.DEFAULT;
        };

        // field name -> message
        // field name -> error code -> message
        // field name -> DEFAULT -> message
        if (isKeyOf(errorTranslationMapWithDefaults, fieldName) && errorTranslationMapWithDefaults[fieldName]) {
            const fieldNameData = errorTranslationMapWithDefaults[fieldName];
            const message = getByErrorCode(fieldNameData);
            if (message) return message;
        }

        // error code -> message
        if (isKeyOf(errorTranslationMapWithDefaults, errorCode) && errorTranslationMapWithDefaults[errorCode]) {
            const message = errorTranslationMapWithDefaults[errorCode];
            if (typeof message === 'string') return message;
        }

        // DEFAULT -> message
        // DEFAULT -> error code -> message
        // DEFAULT -> DEFAULT -> message
        if (isKeyOf(errorTranslationMapWithDefaults, 'DEFAULT') && errorTranslationMapWithDefaults.DEFAULT) {
            const defaultData = errorTranslationMapWithDefaults.DEFAULT;
            const message = getByErrorCode(defaultData);
            if (message) return message;
        }

        // fallback
        return options?.shouldUseIssueCodeAsFallback ? issue.code : issue.message;
    };
};
