import { SectionPreview } from '@/shared/components/SectionPreview.tsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePositionQuote } from '@/CustomRequestProductChannel/hooks/usePositionQuote.ts';
import {
    ProductSelectionSummary,
    ProductSelectionSummaryRow,
} from '@/shared/components/ProductSelection/ProductSelectionSummary.tsx';
import { constructProductQuote } from '@/CustomRequestProductChannel/utils/constructProductQuote.ts';
import { useEditPositionState } from '@/modules/position/hooks/useEditPositionState.ts';
import { PositionSection } from '@/modules/position/components/PositionWizard.tsx';
import { useDeletePositionProductGroup, usePositionProductGroups } from '@/clients/position/usePositionProductGroup.ts';
import { ProductGroupsProps } from '@/shared/components/ProductSelection/ProductGroups.tsx';

type PositionProductSelectionPreviewProps = Pick<PositionSection, 'onEditCurrentSection'> & {
    positionId: string;
};

function RowRenderer({ productId }: { productId: string }) {
    const { data: positionQuote } = usePositionQuote(productId);
    return <ProductSelectionSummaryRow quote={constructProductQuote({ positionQuote })} />;
}

export function PositionProductSelectionPreview({
    onEditCurrentSection,
    positionId,
}: PositionProductSelectionPreviewProps) {
    const { t } = useTranslation();
    const { isEditing } = useEditPositionState();
    const { mutateAsync: mutateDeletePositionProductGroup } = useDeletePositionProductGroup(positionId);
    const positionProductGroups = usePositionProductGroups(positionId);

    // This data transformation is needed due to static typing and component reuse across PositionWizard and OrderWizard.
    // It's an exact copy of the implementation in PositionProductSelection
    const orderProductGroups: ProductGroupsProps['orderProductGroups'] = positionProductGroups.data
        ? positionProductGroups.data.map(group => ({
              ...group,
              orderProducts: group.products?.map(product => ({
                  ...product,
                  orderProductGroupId: product.positionProductGroupId,
                  templateId: product.templateId,
                  positionProductId: null,
                  productConfiguration: product.positionProductConfiguration,
              })),
          }))
        : [];

    const handleDeletePositionProductGroup = async (productGroupId: string) => {
        await mutateDeletePositionProductGroup({ productGroupId });
        onEditCurrentSection();
    };

    const isSummaryAvailable = !!positionProductGroups.data?.length;

    return isSummaryAvailable ? (
        <ProductSelectionSummary
            orderProductGroups={orderProductGroups}
            onEdit={onEditCurrentSection}
            onDelete={handleDeletePositionProductGroup}
            disableDelete={isEditing}
            rowRenderer={RowRenderer}
        />
    ) : (
        <SectionPreview
            description={t('sections.productGroupSelection.description')}
            headline={`2. ${t('sections.productGroupSelection.title')}`}
        />
    );
}
