import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { useFulfillmentContext } from './FulfillmentContext';
import { useFileUpload } from '@/shared/hooks/useFileUpload';
import { isPdfEncrypted } from '@/shared/utils/isPdfEncrypted';
import { useTranslation } from 'react-i18next';

export interface UploadFile {
    file: File;
    progress: number;
    remotePath: string;
    status: 'pending' | 'uploading' | 'uploaded' | 'failed';
    error?: string;
    abortController?: AbortController;
}

type FulfillmentUploadContextType = {
    uploads: UploadFile[];
    addUploads: (fileList: FileList | File[]) => Promise<UploadFile[]>;
    removeUpload: (file: UploadFile) => void;
};

const FulfillmentUploadContext = createContext<FulfillmentUploadContextType | undefined>(undefined);

export const ALLOWED_MIME_TYPE = 'image/jpeg, image/png, application/pdf';
export const MAX_FILE_SIZE_IN_BYTES = 20 * 1024 * 1024;

export const FulfillmentUploadProvider = ({ children }: PropsWithChildren) => {
    const [uploads, setUploads] = useState<UploadFile[]>([]);

    const { uploadFile } = useFileUpload();
    const { t } = useTranslation();

    const { setDocuments } = useFulfillmentContext();

    const updateUploadFile = useCallback(
        (uploadFileToUpdate: Partial<UploadFile> & Pick<UploadFile, 'file'>) => {
            setUploads(uploads =>
                uploads.map(originalUploadFile => {
                    if (originalUploadFile.file === uploadFileToUpdate.file) {
                        return { ...originalUploadFile, ...uploadFileToUpdate };
                    }
                    return originalUploadFile;
                })
            );
        },
        [setUploads]
    );

    const addUploads: FulfillmentUploadContextType['addUploads'] = useCallback(
        async fileList => {
            const pendingFiles: UploadFile[] = [...fileList].map(file => ({
                file,
                progress: 0,
                remotePath: '',
                status: 'pending' as const,
            }));
            // initially adding the files to the state
            setUploads(uploads => [...uploads, ...pendingFiles]);

            // upload the files, care about progress updates and errors
            const uploadPromises = pendingFiles.map(async ({ file }): Promise<UploadFile> => {
                const abortController = new AbortController();
                updateUploadFile({ file, status: 'uploading', abortController });

                // Do not allow encrypted PDFs
                const isEncrypted = await isPdfEncrypted(file);
                if (isEncrypted) {
                    const failedFile = {
                        file,
                        status: 'failed' as const,
                        error: t('fulfillment.documentUpload.errors.pdfIsEncrypted'),
                        progress: 0,
                        remotePath: '',
                    };
                    updateUploadFile(failedFile);
                    return failedFile;
                }

                try {
                    const remotePath = await uploadFile(file, abortController.signal, progress => {
                        updateUploadFile({ file, progress });
                    });
                    const uploadedFile = { file, status: 'uploaded' as const, remotePath, progress: 100 };
                    updateUploadFile(uploadedFile);
                    return uploadedFile;
                } catch {
                    const failedFile = { file, status: 'failed' as const, error: ' ', progress: 0, remotePath: '' };
                    updateUploadFile(failedFile);
                    return failedFile;
                }
            });

            return await Promise.all(uploadPromises);
        },
        [t, updateUploadFile, uploadFile]
    );

    const removeUpload = useCallback(
        (file: UploadFile) => {
            setUploads(uploads => uploads.filter(upload => upload !== file));
        },
        [setUploads]
    );

    useEffect(() => {
        const successfulUploads = uploads.filter(upload => upload.status === 'uploaded');
        setDocuments(successfulUploads.map(upload => ({ url: upload.remotePath, fileName: upload.file.name })));
    }, [uploads, setDocuments]);

    return (
        <FulfillmentUploadContext.Provider value={{ uploads, addUploads, removeUpload }}>
            {children}
        </FulfillmentUploadContext.Provider>
    );
};

export const useFulfillmentUploadContext = () => {
    const context = useContext(FulfillmentUploadContext);
    if (!context) {
        throw new Error('useFulfillmentUploadContext must be used within a FulfillmentUploadProvider');
    }

    const { uploads, addUploads, removeUpload } = context;

    return {
        uploads,
        addUploads,
        removeUpload,
    };
};
