import { usePosition } from '@/clients/position/usePosition.ts';
import { PositionSettingsSectionSummary } from '@/modules/position/components/PositionSettings/PositionSettingsSectionSummary.tsx';
import React from 'react';
import { PositionSection } from '@/modules/position/components/PositionWizard.tsx';
import { SectionPreview } from '@/shared/components/SectionPreview.tsx';
import { useTranslation } from 'react-i18next';

type PositionSettingsPreviewProps = Pick<PositionSection, 'onEditCurrentSection'> & {
    positionId: string;
};

export function PositionSettingsPreview({ positionId, onEditCurrentSection }: PositionSettingsPreviewProps) {
    const { t } = useTranslation();
    const { data: position } = usePosition(positionId);

    const isSummaryAvailable = position && position.validFrom && position.validTo;

    return isSummaryAvailable ? (
        <PositionSettingsSectionSummary
            onEditClick={onEditCurrentSection}
            validFrom={position.validFrom!}
            validTo={position.validTo!}
            active={position.active}
            note={position.note}
        />
    ) : (
        <SectionPreview
            description={t('position.sections.positionSettings.description')}
            headline={`3. ${t('position.sections.positionSettings.title')}`}
        />
    );
}
