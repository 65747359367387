import { useMaybeFulfillmentContext } from '@/modules/fulfillment/context/FulfillmentContext';
import { getMaxDate } from '@/shared/utils/getMaxDate';
import { getMinDate } from '@/shared/utils/getMinDate';
import { toDatePickerValue } from '@/shared/utils/toDatePickerValue';
import { useMemo, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

type ServiceDateRange = [] | [Date, Date];

/**
 * Custom hook to get the summed up line item listing data for a product.
 *
 * This hook gets the lineItems data from the FulfillmentContext (if available) and calculates the total
 * amount and service date range for a given product ID.
 *
 * It is used in the product listing when line item listing is active.
 */
export const useLineItemListingSync = ({
    productId,
    onAmountChange,
    onServiceDateRangeChange,
}: {
    /** The product ID of the product we want to get data for */
    productId: string;
    /** Callback function to handle amount changes. Is called whenever the amount of any lineItem of this product is changed */
    onAmountChange: (amount: number) => void;
    /** Callback function to handle service date range changes. Is called whenever the service date of any lineItem of this product is changed */
    onServiceDateRangeChange: (serviceDate: [] | [Date, Date]) => void;
}) => {
    const { lineItemListing } = useMaybeFulfillmentContext() || {};
    const [serviceDateRange, setServiceDateRange] = useState<ServiceDateRange>([]);
    useDeepCompareEffect(() => {
        if (!lineItemListing) {
            return;
        }

        const relevantLineItems = lineItemListing.filter(lineItem => lineItem.productId === productId);
        const relevantFieldDatasets = relevantLineItems.map(lineItem => ({
            serviceDate: lineItem.serviceDate,
            amount: lineItem.amount,
        }));
        const { serviceDateRange, amount } = relevantFieldDatasets.reduce(
            (acc, lineItem) => {
                const serviceDateRange: ServiceDateRange = [
                    getMinDate(lineItem.serviceDate, ...acc.serviceDateRange),
                    getMaxDate(lineItem.serviceDate, ...acc.serviceDateRange),
                ];
                const amount = acc.amount + lineItem.amount;
                return { serviceDateRange, amount };
            },
            { serviceDateRange: [] as ServiceDateRange, amount: 0 }
        );
        onAmountChange(amount);
        onServiceDateRangeChange(serviceDateRange);
        setServiceDateRange(serviceDateRange);

        return () => {
            onServiceDateRangeChange([]);
            setServiceDateRange([]);
        };
    }, [lineItemListing, {}]);

    const serviceDateRangeForDatePicker = useMemo(() => {
        if (serviceDateRange.length === 0) return null;
        const start = toDatePickerValue(serviceDateRange[0]);
        const end = toDatePickerValue(serviceDateRange[1]);
        if (!start || !end) return null;
        return { start, end };
    }, [serviceDateRange]);

    const isServiceDateRange =
        serviceDateRange.length && serviceDateRange[0].toISOString() !== serviceDateRange[1].toISOString();

    return {
        /** Indicates if the line item listing is active. */
        isLineItemListingActive: !!lineItemListing,
        /** Indicates if the service date is a range. If `false`, this means both elements in `serviceDateRange` are the same */
        isServiceDateRange,
        /** The current service date range. */
        serviceDateRange,
        /** The service date range formatted for the DatePicker component. */
        serviceDateRangeForDatePicker,
    };
};
