import React from 'react';
import { useFeatureFlag } from '@/tools/featureFlags/useFeatureFlag.ts';
import { useTranslation } from 'react-i18next';
import { CustomRequestProductConfiguration } from '@/CustomRequestProductChannel/components/CustomRequestProduct.tsx';

export type CustomRequestProductHintsProps = {
    showProductPriceAddedHint?: boolean;
    showProductPriceNotAddedHint?: boolean;
    showZeroPriceProductConfiguration: boolean;
    isZeroPurchasePrice: boolean;
    isZeroSalesPrice: boolean;
    isNegativePurchasePrice: boolean;
    isNegativeSalesPrice: boolean;
    partnerOrganizationName?: string;
    orderingOrganizationName?: string;
    productConfiguration?: CustomRequestProductConfiguration;
    onUpdateProductConfiguration?: (productConfiguration: Partial<CustomRequestProductConfiguration>) => void;
};

export function CustomRequestProductHints({
    isNegativePurchasePrice,
    isNegativeSalesPrice,
    isZeroSalesPrice,
    isZeroPurchasePrice,
    partnerOrganizationName,
    orderingOrganizationName,
    productConfiguration,
    onUpdateProductConfiguration,
    showProductPriceNotAddedHint,
    showProductPriceAddedHint,
    showZeroPriceProductConfiguration,
}: CustomRequestProductHintsProps) {
    const enableZeroPricesConfigurationFeatureFlag = useFeatureFlag<boolean>('enable-zero-price-configuration');
    const { t } = useTranslation();

    const canShowZeroPriceProductConfiguration =
        enableZeroPricesConfigurationFeatureFlag && showZeroPriceProductConfiguration;

    return (
        <>
            {canShowZeroPriceProductConfiguration && (
                <>
                    {isZeroPurchasePrice && (
                        <div className="font-copy-sm text-subdued mt-4 flex h-4 gap-4">
                            <span>
                                {productConfiguration?.showZeroPurchasePrice
                                    ? t(
                                          'sections.productSelection.productPositionFooter.purchasePricePositionConfiguration',
                                          {
                                              orgName: partnerOrganizationName,
                                          }
                                      )
                                    : t('sections.productSelection.productPositionFooter.zeroPurchaseInfoText', {
                                          orgName: partnerOrganizationName,
                                      })}
                            </span>
                            {
                                //TODO: remove this check when product configuration is implemented in fulfillment and order
                                productConfiguration && (
                                    <span
                                        className="font-copy-sm-strong cursor-pointer whitespace-nowrap"
                                        onClick={() =>
                                            onUpdateProductConfiguration?.({
                                                showZeroPurchasePrice: !productConfiguration?.showZeroPurchasePrice,
                                            })
                                        }
                                    >
                                        {productConfiguration?.showZeroPurchasePrice
                                            ? t('sections.productSelection.productPositionFooter.doNotShowPosition')
                                            : t('sections.productSelection.productPositionFooter.showPosition')}
                                    </span>
                                )
                            }
                        </div>
                    )}
                    {isZeroSalesPrice && (
                        <div className="font-copy-sm text-subdued mt-4 flex h-4 gap-4">
                            <span>
                                {productConfiguration?.showZeroSalesPrice
                                    ? t(
                                          'sections.productSelection.productPositionFooter.salesPricePositionConfiguration',
                                          {
                                              orgName: orderingOrganizationName,
                                          }
                                      )
                                    : t('sections.productSelection.productPositionFooter.zeroSalesInfoText', {
                                          orgName: orderingOrganizationName,
                                      })}
                            </span>
                            {
                                //TODO: remove this check when product configuration is implemented in fulfillment and order
                                productConfiguration && (
                                    <span
                                        className="font-copy-sm-strong cursor-pointer whitespace-nowrap"
                                        onClick={() =>
                                            onUpdateProductConfiguration?.({
                                                showZeroSalesPrice: !productConfiguration?.showZeroSalesPrice,
                                            })
                                        }
                                    >
                                        {productConfiguration?.showZeroSalesPrice
                                            ? t('sections.productSelection.productPositionFooter.doNotShowPosition')
                                            : t('sections.productSelection.productPositionFooter.showPosition')}
                                    </span>
                                )
                            }
                        </div>
                    )}
                </>
            )}
            {isNegativePurchasePrice && (
                <div className="font-copy-sm text-subdued mt-4 h-4">
                    {t('sections.productSelection.productPositionFooter.negativePurchaseInfoText', {
                        orgName: partnerOrganizationName,
                    })}
                </div>
            )}
            {isNegativeSalesPrice && (
                <div className="font-copy-sm text-subdued mt-4 h-4">
                    {t('sections.productSelection.productPositionFooter.negativeSalesInfoText', {
                        orgName: orderingOrganizationName,
                    })}
                </div>
            )}

            {showProductPriceNotAddedHint && (
                <div className="font-copy-sm text-subdued mt-4 h-4">
                    {t('sections.productSelection.productHint.productPriceNotAdded')}
                </div>
            )}
            {showProductPriceAddedHint && (
                <div className="font-copy-sm text-subdued mt-4 h-4">
                    {t('sections.productSelection.productHint.productPriceAdded')}
                </div>
            )}
        </>
    );
}
