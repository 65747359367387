import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router';
import { FullscreenLoadingSpinner } from '@/shared/components/FullscreenLoadingSpinner.tsx';
import { getMarketsFromToken } from '@/shared/utils/getMarketsFromToken.ts';
import { authTokenFromLocalStorage } from '@/shared/utils/localStorage.ts';
import { MarketCodeSchema, setMarketCodeToLocalStorage } from '@/shared/context/MarketContext.tsx';

// NOTE: hotfix copy of MarketContext
function getAvailableMarketCodes() {
    return getMarketsFromToken(authTokenFromLocalStorage())
        .map(m => {
            const result = MarketCodeSchema.safeParse(m);
            return result.success ? result.data : undefined;
        })
        .filter(m => m !== undefined);
}

export const AuthGuard: React.FC = () => {
    const { oktaAuth, authState } = useOktaAuth();

    useEffect(() => {
        if (!authState) {
            return;
        }

        if (!authState?.isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.href, window.location.origin);
            oktaAuth.setOriginalUri(originalUri);
            oktaAuth.signInWithRedirect().catch(error => {
                console.error('Error while redirecting to login', error);
            });
        }
    }, [oktaAuth, authState, authState?.isAuthenticated]);

    if (!authState || !authState?.isAuthenticated) {
        return <FullscreenLoadingSpinner />;
    }

    // NOTE: hotfix for missing market code when app is initialized
    const marketCode = getAvailableMarketCodes().shift();
    if (marketCode) {
        setMarketCodeToLocalStorage(marketCode);
    }

    return <Outlet />;
};
