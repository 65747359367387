import { CalendarDate, parseDate } from '@internationalized/date';
import { DatelikeInput, DatelikeSchema } from './DatelikeSchema';

export const toDatePickerValue = (datelikeInput: DatelikeInput | undefined | null): CalendarDate | undefined => {
    if (!datelikeInput) return;

    const { success, data: date } = DatelikeSchema.safeParse(datelikeInput);
    if (!success) return;

    return parseDate(date.toISOString().slice(0, 10));
};
