import { z } from 'zod';
import { PROJECT_CODE_SCHEMA } from '../project/project.ts';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';

export const PostOrderFulfillUrlSchema = z.object({
    orderId: z.string().uuid(),
});

export const LineItemListingSchema = z.object({
    productId: z.string().uuid(),
    amount: z.number(),
    serviceDate: z.coerce.date(),
    sortIndex: z.number().int(),
    groupKey: z.number().int().optional(),
    referenceDocument: z.string().nullish(),
    licensePlate: z.string().nullish(),
    documentUrls: z.array(z.string()).optional(),
});

const _FulfillmentLineItemListingSchema = LineItemListingSchema.extend({
    fulfillmentId: z.string().uuid(),
});

export type FulfillmentLineItemListing = z.infer<typeof _FulfillmentLineItemListingSchema>;

export const PostOrderFulfillNestedProductSchema = z.object({
    id: z.string().uuid(),
    quoteId: z.string().uuid(),
    name: z.string(),
    amount: z.number(),
    unit: SUPPORTED_UNITS_SCHEMA,
    currencyCode: z.string().length(3),
    partnerOrganizationId: z.number().int().positive(),
    orderingOrganizationId: z.number().int().positive(),
    platformOrganizationId: z.number().int().positive(),
    purchasePrice: z.number(),
    purchaseTaxClassId: z.string(),
    salesPrice: z.number(),
    salesTaxClassId: z.string(),
    // Check that service date is in the past
    serviceDate: z.coerce.date().refine(date => new Date() > date),
});

export const PostOrderFulfillRequestSchema = z
    .object({
        clientInformation: z.object({
            projectCode: PROJECT_CODE_SCHEMA,
            userId: z.number().int(),
            manualCostCenter: z.string(),
        }),
        products: z.array(PostOrderFulfillNestedProductSchema),
        lineItemListing: LineItemListingSchema.array(),
        supportingDocuments: z
            .array(
                z.object({
                    description: z.string(),
                    urlPath: z.string(),
                    fileName: z.string(),
                })
            )
            .nonempty(),
    })
    .refine(
        data => {
            const lineItems = data.lineItemListing;
            const isGroupKeySet = lineItems.some(item => item.groupKey !== undefined);
            if (isGroupKeySet) {
                return lineItems.every(item => item.groupKey !== undefined);
            }
            return true;
        },
        {
            path: ['lineItemListing'],
            message: 'If groupKey is set for one line item, it must be set for all line items.',
        }
    );

export type PostOrderFulfillNestedProduct = z.infer<typeof PostOrderFulfillNestedProductSchema>;
