import { DatelikeSchema } from '@/shared/utils/DatelikeSchema';
import { z } from 'zod';

export const IndividualLineItemSchema = z.object({
    productId: z.string().uuid(),
    amount: z.number(),
    serviceDate: DatelikeSchema,
    referenceDocument: z.string().nullish(),
    licensePlate: z.string().nullish(),
    documentUrls: z.array(z.string()).optional(),
});

export const PartialIndividualLineItemSchema = IndividualLineItemSchema.partial();

export type IndividualLineItem = z.infer<typeof IndividualLineItemSchema>;
export type PartialIndividualLineItem = z.infer<typeof PartialIndividualLineItemSchema>;
export type PartialIndividualLineItemInput = z.input<typeof PartialIndividualLineItemSchema>;
