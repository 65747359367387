import SimpleState from '@schuettflix/util-simple-state';
import useSimpleState from '@schuettflix/util-simple-state-react';
import { config, ENVIRONMENT_LONG_NAME, getUrls } from '@schuettflix/urls';

export interface ExternalGlobals {
    hostAppVersion: string;
    userLocale: SimpleState<string>;
    accessToken: SimpleState<string>;
    baseUrl: {
        backend: SimpleState<string>;
        constructionProject: SimpleState<string>;
    };
    environment: SimpleState<string>;
}

export interface Globals extends ExternalGlobals {
    config: SimpleState<typeof config>;
}

let globals: ExternalGlobals | undefined = undefined;

export const installGlobals = (newGlobals: ExternalGlobals) => {
    globals = newGlobals;
};

export const resolveCurrentUrlEnv = (): ENVIRONMENT_LONG_NAME => {
    const currentUrl = window.location.href;

    if (currentUrl.includes('.stage.')) {
        return ENVIRONMENT_LONG_NAME.STAGE;
    } else if (currentUrl.includes('order.schuettflix.com')) {
        return ENVIRONMENT_LONG_NAME.PRODUCTION;
    } else if (currentUrl.includes('.dev.')) {
        return ENVIRONMENT_LONG_NAME.DEVELOPMENT;
    } else if (currentUrl.includes('.test.')) {
        return ENVIRONMENT_LONG_NAME.TEST;
    } else if (currentUrl.includes('.local.')) {
        return ENVIRONMENT_LONG_NAME.LOCAL;
    } else if (currentUrl.includes('localhost')) {
        return ENVIRONMENT_LONG_NAME.LOCAL;
    } else if (currentUrl.includes('.netsuite.')) {
        return ENVIRONMENT_LONG_NAME.NETSUITE;
    } else if (currentUrl.includes('.sandbox.')) {
        return ENVIRONMENT_LONG_NAME.SANDBOX;
    } else if (currentUrl.includes('.demo.')) {
        return ENVIRONMENT_LONG_NAME.DEMO;
    } else if (currentUrl.includes('training')) {
        return ENVIRONMENT_LONG_NAME.TRAINING;
    } else if (currentUrl.includes('dg12aj60hi4eh.amplifyapp.com')) {
        return ENVIRONMENT_LONG_NAME.DEVELOPMENT;
    }

    return ENVIRONMENT_LONG_NAME.STAGE;
};

// This function ensures that the environment according to SFLX standards
// gets mapped correctly to long names used in `@schuettflix/urls` package.
// See: https://schuettflix.atlassian.net/wiki/spaces/SCHUT/pages/555679951/Environment+Glossary
function resolveSflxEnvironment() {
    switch (import.meta.env.VITE_SFLX_ENVIRONMENT) {
        case 'prod':
            return ENVIRONMENT_LONG_NAME.PRODUCTION;
        case 'demo':
            return ENVIRONMENT_LONG_NAME.DEMO;
        case 'stage':
            return ENVIRONMENT_LONG_NAME.STAGE;
        case 'training':
            return ENVIRONMENT_LONG_NAME.TRAINING;
        case 'netsuite':
            return ENVIRONMENT_LONG_NAME.NETSUITE;
        case 'sandbox':
            return ENVIRONMENT_LONG_NAME.SANDBOX;
        case 'dev':
            return ENVIRONMENT_LONG_NAME.DEVELOPMENT;
        default:
            return undefined;
    }
}

export const resolveSflxEnv = (installationPropEnv?: string): ENVIRONMENT_LONG_NAME => {
    // NOTE: The installationPropEnv are not used even though often passed to the function by consumers!!!
    // `resolveCurrentUrlEnv` always returns a value hence the argument passed is never used.
    return resolveSflxEnvironment() || resolveCurrentUrlEnv() || installationPropEnv;
};

export function useGlobals() {
    if (!globals) {
        throw new Error(
            'Globals have not been set yet, which should be the case. Make sure to set the globals during module installation within the <onInstall> lifecycle.'
        );
    }

    const [accessToken] = useSimpleState(globals.accessToken);
    const [backend] = useSimpleState(globals.baseUrl.backend);
    const [constructionProject] = useSimpleState(globals.baseUrl.constructionProject);
    const [environment] = useSimpleState(globals.environment);

    const resolvedNodeEnv = resolveSflxEnv(environment);

    const config = getUrls(resolvedNodeEnv, import.meta.env.VITE_BRANCH_NAME_ENV);

    config.ORDER_SERVICE_URL = import.meta.env.VITE_ORDER_SERVICE_URL;
    config.CUSTOM_REQUEST_SERVICE_URL = import.meta.env.VITE_CUSTOM_REQUEST_SERVICE_URL;

    // This is a workaround for the local development environment
    if (import.meta.env.VITE_LOCAL_API) {
        config.ORDER_SERVICE_URL = 'http://localhost:4000/order-service/local';
        config.FULFILLMENT_SERVICE_URL = 'http://localhost:4000/order-fulfillment-service/local';
        config.CUSTOM_REQUEST_SERVICE_URL = 'http://localhost:4000/custom-request-service/local';
    }

    return {
        accessToken,
        hostAppVersion: globals.hostAppVersion,
        baseUrl: {
            backend,
            constructionProject,
        },
        environment: resolvedNodeEnv,
        config: config,
    };
}

export type UseGlobalsData = ReturnType<typeof useGlobals>;
