import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateOrganizationFinanceSettings } from '@/clients/order/order.ts';
import { PatchPaymentTermRequestSchema } from '@schuettflix/interfaces';
import { z } from 'zod';
import { getOrderSummaryQueryKey } from '@/clients/order/useOrderSummary.ts';
import { getPositionSummaryQueryKey } from '@/clients/position/usePositionSummary.ts';

interface PayloadType {
    financeSettings: z.input<typeof PatchPaymentTermRequestSchema>;
    organizationId: number;
}

export const useUpdateOrganizationFinanceSettings = (referenceId: string) => {
    const queryClient = useQueryClient();

    return {
        updateOrganizationFinanceSettings: useMutation({
            mutationFn: (payload: PayloadType) =>
                updateOrganizationFinanceSettings(payload.financeSettings, payload.organizationId, referenceId),
            onSuccess: () => {
                void queryClient.invalidateQueries({ queryKey: getPositionSummaryQueryKey(referenceId) });
                void queryClient.invalidateQueries({ queryKey: getOrderSummaryQueryKey(referenceId) });
                void queryClient.invalidateQueries({ queryKey: ['fulfillment-summary', referenceId] });
            },
        }),
    };
};
