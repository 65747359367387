import React from 'react';
import { PositionSection } from '@/modules/position/components/PositionWizard.tsx';
import { QueryErrorBoundary } from '@/shared/components/QueryErrorBoundary';
import { SectionErrorFallbackWithReset } from '@/shared/components/SectionErrorFallback';
import { cn } from '@/shared/utils/cn.ts';
import { InlineLoadingSuspenseBoundary } from '@/shared/components/InlineLoadingSuspenseBoundary.tsx';

export interface PositionSectionWrapperProps {
    className?: string;
    children: React.ReactElement<PositionSection>;
    showDividers?: boolean;
    showTopDivider?: boolean;
    showBottomDivider?: boolean;
}

export const PositionSectionWrapper = ({
    className,
    children,
    showDividers,
    showTopDivider,
    showBottomDivider,
}: PositionSectionWrapperProps) => {
    return (
        <>
            {(showDividers || showTopDivider) && (
                <div className="border-t-divider my-6 w-full max-w-[720px] border-t" />
            )}
            <InlineLoadingSuspenseBoundary>
                <section className={cn('flex w-full max-w-[720px] flex-col items-center', className)}>
                    <QueryErrorBoundary fallbackComponent={SectionErrorFallbackWithReset}>
                        {children}
                    </QueryErrorBoundary>
                </section>
            </InlineLoadingSuspenseBoundary>
            {(showDividers || showBottomDivider) && (
                <div className="border-t-divider my-6 w-full max-w-[720px] border-t" />
            )}
        </>
    );
};
