import { z } from 'zod';
import { PostCustomerInfoRequestSchema } from '../customer-info/post-customer-info.ts';
import { PostProductGroupRequestSchema, PostProductGroupResponseSchema } from '../product-group/post-product-group.ts';
import {
    PostOrderProductConfigurationRequestSchema,
    PostOrderProductRequestSchema,
} from '../order-product/post-order-product.ts';
import { PostOrderResponseSchema } from './post-order.ts';

const PositionProductExtensionSchema = z.object({
    positionProductId: z.string().uuid(),
    orderProductConfiguration: PostOrderProductConfigurationRequestSchema,
});

const OrderDataSchema = z.object({
    positionId: z.string().uuid(),
    positionNumber: z.string(),
    marketCode: z.string(),
    customerInfoData: PostCustomerInfoRequestSchema,
    productGroupData: z.array(
        PostProductGroupRequestSchema.omit({ orderId: true }).merge(
            z.strictObject({
                productData: z.array(PostOrderProductRequestSchema.merge(PositionProductExtensionSchema)),
            })
        )
    ),
});

export const PostPositionOrderRequestSchema = z.object({
    orderData: OrderDataSchema,
});

export const PostPositionOrderResponseSchema = PostOrderResponseSchema.merge(
    z.strictObject({
        createdBy: z.number(),
        productGroups: z.array(PostProductGroupResponseSchema),
    })
);
