import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionPreview } from '../SectionPreview';
import { InlineLoadingSpinner } from '@/shared/components/InlineLoadingSpinner.tsx';

type ProductSelectionProps = {
    isOpen: boolean;
    hasProductGroups: boolean;
    isLoading: boolean;
    summarySlot: React.ReactNode;
    presetSelectionSlot: React.ReactNode;
    productGroupsSlot: React.ReactNode;
};

export function ProductSelection({
    isOpen,
    hasProductGroups,
    isLoading,
    summarySlot,
    presetSelectionSlot,
    productGroupsSlot,
}: ProductSelectionProps) {
    const { t } = useTranslation();

    if (!isOpen) {
        if (!hasProductGroups) {
            return (
                <SectionPreview
                    description={t('sections.productGroupSelection.description')}
                    headline={`2. ${t('sections.productGroupSelection.title')}`}
                />
            );
        }
        return <>{summarySlot}</>;
    }

    return (
        <div className="flex w-full flex-col items-center">
            {isLoading ? (
                <InlineLoadingSpinner className="p-[95px]" />
            ) : !hasProductGroups ? (
                <div className="max-w-[720px]">{presetSelectionSlot}</div>
            ) : (
                <>{productGroupsSlot}</>
            )}
        </div>
    );
}
